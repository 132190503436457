<template>
  <div class="container mb-5" v-if="course">
    <div class="container pt-3 pb-1">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="#"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/admin/questions">Bank Soalan</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ course.course_name }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6">
          <h2>{{ course.course_name }}</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3 bg-white p-1 pt-0" style="background-clip: content-box">
        <h5 class="m-3">Topik</h5>
        <ul class="list-group" v-if="topics.length" id="topic-list">
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              custom-list
              ms-2
              me-2
            "
            v-for="(topic, i) in topics"
            :key="'topic' + i"
            :id="'topic' + i"
            @click="selectTopic(topic, i)"
          >
            {{ i + 1 }}. {{ topic.topic_name }}
            <span>
              <i class="bi bi-chevron-right"></i>
            </span>
          </li>
        </ul>
      </div>
      <div class="col-3 bg-white p-1 pt-0" style="background-clip: content-box">
        <!-- <h5 class="m-3">Subtopik</h5> -->
        <h5 class="m-3">Kandungan</h5>
        <ul class="list-group" v-if="subtopics.length" id="subtopic-list">
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              custom-list
              ms-2
              me-2
            "
            v-for="(subtopic, i) in subtopics"
            :key="'subtopic' + i"
            :id="'subtopic' + i"
            @click="selectSubtopic(subtopic, i)"
          >
            {{ i + 1 }}. {{ subtopic.subtopic_name }}
            <span>
              <i class="bi bi-chevron-right"></i>
            </span>
          </li>
        </ul>
        <div v-else style="text-align: center">Sila pilih topik dahulu.</div>
      </div>
      <div class="col-6 bg-white p-1 pt-0" style="background-clip: content-box">
        <div class="row">
          <div class="col-8 p-1">
            <h5 class="ms-4 mt-2">Soalan</h5>
          </div>
          <div class="col-4 p-1">
            <button
              v-if="selectedSubtopicId && selectedTopicId"
              class="btn btn-outline-secondary"
              @click="removeNewQuestion(); isEdit = false"
              data-bs-toggle="modal"
              data-bs-target="#addQuestionModal"
            >
              Tambah Soalan +
            </button>
          </div>
        </div>
        <ul class="list-group mt-2" v-if="questions.length">
          <li
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
              custom-list
              ms-4
              me-4
            "
            v-for="(question, i) in questions"
            :key="'topic' + i"
          >
            <div style="float: left; width: 80%">{{ i + 1 }}. {{ question.question }}</div>
            <div style="float: right">
              <button
                class="bi bi-trash custom-btn"
                type="button"
                @click="deleteQuestion(question, i)"
              ></button>
              <button
                class="bi bi-pencil custom-btn"
                type="button"
                @click="editQuestion(question)"
                data-bs-toggle="modal"
                data-bs-target="#addQuestionModal"
              ></button>
            </div>
          </li>
        </ul>
        <div v-else style="text-align: center">Sila pilih subtopik dahulu.</div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="addQuestionModal"
      tabindex="-1"
      aria-labelledby="addQuestionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-12 mt-2">
                <h5>Soalan Baru</h5>
                <div v-if="!isEdit">
                  <div class="file-upload-box" v-if="!questionForm.imgFile">
                    <div class="file-upload-box-content">
                      <input
                        accept="image/png,image/jpeg,application/pdf"
                        type="file"
                        id="relatedFile"
                        lang="es"
                        @change="fileUploaded"
                        ref="relatedFile"
                        hidden
                      />
                      <label for="relatedFile" class="title d-block"
                        >Klik untuk memuat naik</label
                      >
                      <span class="d-block">PDF, JPG atau PNG (max 10MB)</span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="list-group" style="border: 1px solid black">
                      <li class="list-group-item file-list">
                        <i
                          class="bi bi-file-earmark-pdf file-icon"
                        ></i>
                        <span class="ms-2">{{
                          questionForm.imgFile.name
                        }}</span>
                        <span
                          class="float-end file-icon"
                          @click="questionForm.imgFile = null"
                        >
                          <i class="fas fa-trash"></i>
                        </span>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1">Soalan</label>
                  <textarea
                    type="text"
                    rows="3"
                    class="form-control"
                    v-model="questionForm.question"
                    placeholder="Masukkan soalan"
                  ></textarea>
                </div>
                <div class="form-group mt-3 custom-checkbox">
                  <input class="form-check-input" type="checkbox" v-model="questionForm.is_active" id="active">
                  <label class="form-check-label ms-3" for="active">
                    Aktif
                  </label>
                </div>
                <div class="form-group mt-3 custom-checkbox">
                  <input class="form-check-input" type="checkbox" v-model="questionForm.is_for_quiz" id="kuiz">
                  <label class="form-check-label ms-3" for="kuiz">
                    Untuk Kuiz
                  </label>
                </div>
                <div class="form-group mt-3 custom-checkbox">
                  <input class="form-check-input" style="width: 70%; height: 80%" type="checkbox" v-model="questionForm.is_for_test" id="ujian">
                  <label class="form-check-label ms-3" for="ujian">
                    Untuk Ujian
                  </label>
                </div>
                <div class="form-group mt-3">
                  <div>
                    <label for="name" class="control-label mt-1"
                      >Pilihan Jawapan</label
                    >
                    <a class="mb-2 float-end" @click="addAnswer"
                      >Tambah Pilihan Jawapan +</a
                    >
                  </div>
                  <div
                    class="row mx-0 mb-2"
                    style="width: 100%"
                    v-for="(item, i) in questionForm.answers"
                    :key="'answer' + i"
                  >
                    <div class="col-11 mx-0 px-0">
                      <input
                        type="text"
                        class="form-control pe-0"
                        v-model="item.answer"
                        placeholder="Jawapan (sila tanda petak jika jawapan betul)"
                      />
                    </div>
                    <div class="col-1 mx-0 pe-0">
                      <input
                        type="checkbox"
                        v-model="item.is_correct"
                        class="form-check-input float-end"
                        style="width: 70%; height: 80%"
                        value=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 justify-content-center">
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="removeNewQuestion"
            >
              Padam
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              v-if="isEdit"
              type="button"
              class="btn btn-primary"
              @click="submitEditQuestion"
            >
              Ubah
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click="submitAddQuestion"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      isEdit: false,
      course: null,
      topics: [],
      selectedTopicId: null,
      subtopics: [],
      selectedSubtopicId: null,
      questions: [],
      questionForm: {
        question: null,
        is_active: null,
        is_for_quiz: null,
        is_for_test: null,
        answers: [],
        imgFile: null,
        img_file: null,
      },
      selectedQuestionId: null
    };
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get(
        `courses/${this.$route.params.courseid}?populate[topics][populate]=*`
      );
      this.course = Object.assign(
        {},
        { id: res.data.data.id },
        res.data.data.attributes
      );
      this.topics = this.course.topics.data.map((o) => {
        return Object.assign({}, { id: o.id }, o.attributes);
      });
      this.topics.forEach((o) => {
        o.subtopics = o.subtopics.data.map((o) => {
          return Object.assign({}, { id: o.id }, o.attributes);
        });
      });
    },
    selectTopic(topic, i) {
      $("#subtopic-list>li.active").removeClass("active")
      $("#topic-list>li.active").removeClass("active")
      $("#topic" + i).addClass("active")
      this.subtopics = [...topic.subtopics]
      this.selectedTopicId = topic.id
      this.selectedSubtopicId = null
    },
    async selectSubtopic(subtopic, i) {
      $("#subtopic-list>li.active").removeClass("active");
      $("#subtopic" + i).addClass("active");
      const qs = require("qs");
      const query = qs.stringify(
        {
          populate: "*",
          filters: {
            subtopic: {
              id: {
                $eq: subtopic.id,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      const res = await API.get(`question-banks?${query}`);
      this.questions = res.data.data.map((o) => {
        return Object.assign({}, { id: o.id }, o.attributes);
      });
      this.selectedSubtopicId = subtopic.id;
    },
    fileUploaded() {
      if (this.$refs.relatedFile && this.$refs.relatedFile.files[0]) {
        this.questionForm.imgFile = this.$refs.relatedFile.files[0];
        console.log(this.questionForm.imgFile);
      }
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("files", this.questionForm.imgFile);
      const resFileUpload = await API.post("upload", formData);
      return resFileUpload.data[0].id;
    },
    addAnswer() {
      let answerObj = {
        answer: "",
        is_correct: false,
      };
      if (this.questionForm.answers.length == 0)
        this.questionForm.answers.push(answerObj);
      else {
        if (
          this.questionForm.answers[this.questionForm.answers.length - 1]
            .answer !== ""
        )
          this.questionForm.answers.push(answerObj);
      }
    },
    removeNewQuestion() {
      this.questionForm = {
        question: null,
        is_active: null,
        is_for_quiz: null,
        is_for_test: null,
        answers: [],
        imgFile: null,
        img_file: null,
      };
    },
    async deleteQuestion(question, i) {
      const res = await API.delete('question-banks/' + question.id)
		  if (res.toDelete)
        this.questions.splice(i, 1)
    },
    editQuestion(question) {
      this.removeNewQuestion()
      this.isEdit = true;
      this.selectedQuestionId = question.id
      this.questionForm = {
        question: question.question,
        is_active: question.is_active,
        is_for_quiz: question.is_for_quiz,
        is_for_test: question.is_for_test,
        imgFile: null,
        img_file: null,
        answers: question.answers,
      };
    },
    async submitEditQuestion() {
      const res = await API.put(`question-banks/${this.selectedQuestionId}?populate=*`, { data:this.questionForm })
      const processedData = Object.assign(res.data.data.attributes, res.data.data.id);
      this.questions.forEach((q) => {
        if (q.id == this.selectedQuestionId)
          q = Object.assign(q, processedData);
      });
      $("#addQuestionModal").toggle();
      $(".modal-backdrop").remove();
    },
    async submitAddQuestion() {
      if (this.questionForm.imgFile)
        this.questionForm.img_file = await this.uploadFile();
      this.questionForm.course = this.$route.params.courseid;
      this.questionForm.topic = this.selectedTopicId;
      this.questionForm.subtopic = this.selectedSubtopicId;
      const res = await API.post("question-banks?populate=*", { data: this.questionForm });
      this.questions.push(Object.assign({}, { id: res.data.data.id }, res.data.data.attributes))
      this.removeNewQuestion();
      $("#addQuestionModal").toggle();
      $(".modal-backdrop").remove();
    },
    async submitAddCourse() {
      // const res = await API.post('courses', { data: this.courseForm })
      $("#addCourseGroupModal").toggle();
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.custom-list {
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  &.active {
    background-color: #8bc53f !important;
  }
}

.custom-checkbox {
  input {
    height: 18px !important;
    width: 18px !important;
  }
  label {
    font-size: 18px !important;
  }
}
.custom-btn {
  background-color: transparent;
  border: 0;
  font-size: 24px;
}
</style>